import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import getSymbolFromCurrency from 'currency-symbol-map'
import { Button, Input, Modal, InputNumber, DatePicker, Form, Select } from 'antd'
import NumberInput from 'components/app/common/NumberInput'
import ServicesAction from 'redux/services/actions'
import { paymentType } from 'utils/data'
import moment from 'moment'

const { Option } = Select

const mapStateToProps = ({ dispatch, services, user }) => ({
  dispatch,
  services,
  user,
})

const RemoveModal = ({ visible, onCancel, loading, dispatch, infodata }) => {
  const { formatMessage } = useIntl()
  const [showCheque, setshowCheque] = useState(false)
  const [showaccountTransfer, setshowaccountTransfer] = useState(false)
  const [showGpay, setshowGpay] = useState(false)
  const [showCash, setshowCash] = useState(false)
  const [showCard, setshowCard] = useState(false)

  // function disabledDate(current) {
  //   return current > moment().startOf('day')
  // }



  console.log("infodata", infodata)

  const dateFormat = 'YYYY-MM-DD'
  const [valueone] = useState(new Date())
  const datevalue = moment(valueone).format('YYYY-MM-DD')

  const currencyInfo = 'INR'

  const onFinish = values => {
    const serviceRequestId = infodata?.serviceDetail?._id
    const serviceHistoryId = infodata?.serviceHistoryDetail?._id
    let PayloadRes
    if (values?.payment_type === '1') {
      PayloadRes = {
        service: serviceRequestId,
        serviceHistory: serviceHistoryId,
        billamount: values?.billamount,
        payment_type: values?.payment_type,
        cheque_no: values?.cheque_no,
        payment_date: values?.payment_date.format('YYYY-MM-DD HH:MM:SS A'),
      }
    } else if (values?.payment_type === '2') {
      PayloadRes = {
        service: serviceRequestId,
        serviceHistory: serviceHistoryId,
        billamount: values?.billamount,
        payment_type: values?.payment_type,
        transaction_id: values?.transaction_id,
        payment_date: values?.payment_date.format('YYYY-MM-DD HH:MM:SS A'),
      }
    } else if (values?.payment_type === '3') {
      PayloadRes = {
        service: serviceRequestId,
        serviceHistory: serviceHistoryId,
        billamount: values?.billamount,
        payment_type: values?.payment_type,
        transaction_id: values?.transaction_id,
        payment_date: values?.payment_date.format('YYYY-MM-DD HH:MM:SS A'),
      }
    } else if (values?.payment_type === '4') {
      PayloadRes = {
        service: serviceRequestId,
        serviceHistory: serviceHistoryId,
        billamount: values?.billamount,
        payment_type: values?.payment_type,
        payment_date: values?.payment_date.format('YYYY-MM-DD HH:MM:SS A'),
      }
    } else if (values?.payment_type === '5') {
      PayloadRes = {
        service: serviceRequestId,
        serviceHistory: serviceHistoryId,
        billamount: values?.billamount,
        payment_type: values?.payment_type,
        payment_date: values?.payment_date.format('YYYY-MM-DD HH:MM:SS A'),
      }
    }
    dispatch({
      type: ServicesAction.UPDATE_SERVICE_REQUEST,
      payload: PayloadRes,
    })
  }

  const [form] = Form.useForm()

  const onValuesChange = changedValues => {
    if (changedValues?.payment_type === '1') {
      setshowCheque(true)
      setshowaccountTransfer(false)
      setshowGpay(false)
      setshowCash(false)
      setshowCard(false)
    } else if (changedValues?.payment_type === '2') {
      setshowCheque(false)
      setshowaccountTransfer(true)
      setshowCash(false)
      setshowGpay(false)
      setshowCard(false)
    } else if (changedValues?.payment_type === '3') {
      setshowCheque(false)
      setshowaccountTransfer(false)
      setshowGpay(true)
      setshowCash(false)
      setshowCard(false)
    } else if (changedValues?.payment_type === '4') {
      setshowCheque(false)
      setshowaccountTransfer(false)
      setshowGpay(false)
      setshowCash(true)
      setshowCard(false)
    } else if (changedValues?.payment_type === '5') {
      setshowCheque(false)
      setshowaccountTransfer(false)
      setshowGpay(false)
      setshowCash(false)
      setshowCard(true)
    }
  }


  useEffect(() => {
    if (infodata?.serviceHistoryDetail) {
      form.setFieldsValue({
        billamount: infodata?.serviceHistoryDetail?.billamount,
        payment_type: infodata?.serviceHistoryDetail?.payment_type,
        payment_date: moment(infodata?.serviceDetail?.service_end_date),
      })
    } // eslint-disable-next-line
    if (infodata?.serviceHistoryDetail?.cheque_no) {
      form.setFieldsValue({
        cheque_no: infodata?.serviceHistoryDetail?.cheque_no,
      })
    }
    if (infodata?.serviceHistoryDetail?.billamount) {
      form.setFieldsValue({
        billamount: infodata?.serviceHistoryDetail?.billamount,
      })
    }
    const sendpayload = { "payment_type": infodata?.serviceHistoryDetail?.payment_type }
    onValuesChange(sendpayload)
  }, [form, infodata])

  return (
    <Modal title="Update Service" visible={visible} onCancel={onCancel} footer={null}>
      <Form layout="vertical" onFinish={onFinish} form={form} onValuesChange={onValuesChange}>
        <Form.Item
          name="billamount"
          label={formatMessage({ id: 'form.label.billamount' })}
          rules={[
            { required: true, message: formatMessage({ id: 'form.error.required' }) },
            () => ({
              validator(rule, value) {
                if (!value || parseFloat(value) > 0) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(formatMessage({ id: 'form.error.billamount.min' })))
              },
            }),
          ]}
        >
          <NumberInput
            placeholder={formatMessage({ id: 'form.placeholder.billamount' })}
            size="large"
            addonBefore={getSymbolFromCurrency(currencyInfo)}
          />
        </Form.Item>
        <Form.Item
          name="payment_type"
          label={formatMessage({ id: 'form.label.payment_type' })}
          rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
        >
          <Select
            placeholder={formatMessage({ id: 'form.placeholder.payment_type' })}
            size="large"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {paymentType?.map(o => {
              return (
                <Option key={o.key} value={o.key}>
                  {o.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        {showCheque === true && (
          <>
            <Form.Item
              name="cheque_no"
              label={formatMessage({ id: 'form.label.cheque_no' })}
              rules={[{ required: true, message: 'Missing Cheque Number' }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                size="large"
                min={0}
                placeholder="Cheque Number"
              />
            </Form.Item>
            <Form.Item name="payment_date" label={formatMessage({ id: 'form.label.payment_date' })}>
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                allowClear={false}
                // disabledDate={disabledDate}
                selected={datevalue}
                format={dateFormat}
              />
            </Form.Item>
          </>
        )}

        {showaccountTransfer === true && (
          <>
            <Form.Item
              name="transaction_id"
              label={formatMessage({ id: 'form.label.transaction_id' })}
              rules={[{ required: true, message: 'Missing Transaction ID' }]}
            >
              <Input style={{ width: '100%' }} size="large" min={0} placeholder="Transaction ID" />
            </Form.Item>
            <Form.Item
              name="payment_mode"
              label={formatMessage({ id: 'form.label.payment_mode' })}
              rules={[
                {
                  required: true,
                  message: formatMessage({ id: 'form.error.required' }),
                },
              ]}
            >
              <Select
                placeholder={formatMessage({ id: 'form.placeholder.payment_mode' })}
                size="large"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                return (
                <Option key="NEFT" value="NEFT">
                  NEFT
                </Option>
                <Option key="IMPS" value="IMPS">
                  IMPS
                </Option>
                <Option key="RTGS" value="RTGS">
                  RTGS
                </Option>
                )
              </Select>
            </Form.Item>
            <Form.Item name="payment_date" label={formatMessage({ id: 'form.label.payment_date' })}>
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                allowClear={false}
                // disabledDate={disabledDate}
                selected={datevalue}
                format={dateFormat}
              />
            </Form.Item>
          </>
        )}
        {showGpay === true && (
          <>
            <Form.Item
              name="transaction_id"
              label={formatMessage({ id: 'form.label.UPItransaction_id' })}
              rules={[{ required: true, message: 'Missing Transaction ID' }]}
            >
              <Input style={{ width: '100%' }} size="large" min={0} placeholder="Transaction ID" />
            </Form.Item>
            <Form.Item name="payment_date" label={formatMessage({ id: 'form.label.payment_date' })}>
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                allowClear={false}
                // disabledDate={disabledDate}
                selected={datevalue}
                format={dateFormat}
              />
            </Form.Item>
          </>
        )}
        {showCash === true && (
          <>
            <Form.Item name="payment_date" label={formatMessage({ id: 'form.label.payment_date' })}>
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                allowClear={false}
                // disabledDate={disabledDate}
                selected={datevalue}
                format={dateFormat}
              />
            </Form.Item>
          </>
        )}
        {showCard === true && (
          <>
            <Form.Item name="payment_date" label={formatMessage({ id: 'form.label.payment_date' })}>
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                allowClear={false}
                // disabledDate={disabledDate}
                selected={datevalue}
                format={dateFormat}
              />
            </Form.Item>
          </>
        )}
        <div className="text-right">
          <Button size="medium" type="secondary" className="mr-3" secondary onClick={onCancel}>
            {formatMessage({ id: 'action.Cancel' })}
          </Button>
          <Button size="medium" type="primary" htmlType="submit" success loading={loading}>
            {formatMessage({ id: 'action.approve' })}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default connect(mapStateToProps)(injectIntl(RemoveModal))
