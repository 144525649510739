import React, { Suspense, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Table, Input, Form, Button, Select, Spin, Badge, Card, Tooltip, Tag } from 'antd'
import { injectIntl } from 'react-intl'
import { selectServices, selectedServicesData } from 'redux/services/selectors'
// import CardTitle from 'components/app/common/CardTitle'
import { createStructuredSelector } from 'reselect'
import { getFormattedDate } from 'utils/parser'
import VehicleAction from 'redux/vehicle/actions'
import ServicesAction from 'redux/services/actions'
import CloseRequest from '../../Services/CloseRequest'
import ModifyCloseRequest from '../../Services/ModifyCloseRequest'


const { Search } = Input

const { Option } = Select

const mapStateToProps = () => createStructuredSelector({
  services: selectServices,
  selectedServices: selectedServicesData,
})

const StoreSettings = ({ apiRes, services, intl: { formatMessage } }) => {
  const dispatch = useDispatch()
  const path = window.location.pathname
  const userId = path.replace('/manage/vehicle/', '')
  const [page, setPage] = useState(1)

  const onSearch = value => {
    dispatch({
      type: VehicleAction.SET_STATE,
      payload: { searchServices: value },
    })
  }

  const UpdateService = values => {
    console.log('values', values)
  }

  const showPopupIndService = values => {
    console.log('showPopupIndService called, values:', values);
    if (values?.is_open)
      dispatch({
        type: ServicesAction.SHOW_POPU_IND_SERVICE,
        payload: values,
      })
  }

  const hidePopupIndService = () => {
    dispatch({
      type: ServicesAction.HIDE_POPU_IND_SERVICE,
    })
  }



  const showModifyPopupIndService = values => {
    if (values?.is_open === false)
      dispatch({
        type: ServicesAction.SHOW_MODIFY_POPUP_IND_SERVICE,
        payload: values?._id,
      })
  }

  const hideModifyIPopupIndService = values => {
    dispatch({
      type: ServicesAction.HIDE_MODIFY_POPUP_IND_SERVICE,
      payload: values?._id,
    })
  }





  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.current_status' }),
      render: (record, text) => (
        <>
          <Badge.Ribbon text={record?.current_status}>
            <Card
              title={`${record?.service_reuest_id.toUpperCase()} - ${record?.service_type.toUpperCase()} | ${record?.category.toUpperCase()}`}
              size="small"
            >
              <center style={{ fontWeight: 'bold' }}>
                {`${text?.vehicle} - ${text?.owner_name} `}
                <Tag
                  color="green"
                  style={{ textTransform: 'capitalize' }}
                >
                  {record?.vehicle_sno}
                </Tag>
              </center>
            </Card>
          </Badge.Ribbon>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.vendorDetails' }),
      render: text => <>{`${text?.vendor_name} - ${text?.vendor_city} `}</>,
    },
    {
      title: formatMessage({ id: 'text.description' }),
      dataIndex: 'description',
      key: 'description',
      render: text => (
        <>
          <Tooltip placement="top" title={text}>
            <Button type="primary">View Message</Button>
          </Tooltip>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.service_start_date' }),
      dataIndex: 'service_start_date',
      key: 'service_start_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.service_end_date' }),
      dataIndex: 'service_end_date',
      key: 'service_end_date',
      render: text => getFormattedDate(text),
    },
    {
      title: formatMessage({ id: 'text.current_status' }),
      render: (text, record) => (
        <>
          <Tag
            onClick={() => showPopupIndService(record)}
            color={(() => {
              switch (text?.current_status) {
                case 'open':
                  return 'red'
                case 'closed':
                  return 'green'
                default:
                  return 'blue'
              }
            })()}
            style={{ textTransform: 'capitalize' }}
          >
            {record?.current_status}
          </Tag>
          <i
            className="fe fe-edit mr-1"
            title="Modify Payment"
            onClick={() => showModifyPopupIndService(record)}
            hidden={record?.current_status === 'open'}
            aria-hidden="true"
          />
        </>
      ),
    },
  ]

  const [form] = Form.useForm()

  const onValuesChange = changedValues => {
    if (changedValues?.current_status === 'open') {
      dispatch({
        type: VehicleAction.SERVICE_FILTER,
        payload: {
          current_status: changedValues?.current_status,
          vehicleId: userId,
        },
      })
    } else {
      dispatch({
        type: VehicleAction.SERVICE_FILTER,
        payload: {
          current_status: changedValues?.current_status,
          vehicleId: userId,
        },
      })
    }
  }

  const clearButton = () => {
    dispatch({
      type: VehicleAction.LOAD_SINGLE_SERVICE,
      payload: {
        vehicleId: userId,
      },
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.servicesData' }),
  }


  console.log(services, "services");

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="mb-4">
            <div className="row">
              <div className="col-md-3">
                <Form.Item>
                  <Search
                    style={{ width: '100%' }}
                    placeholder={formatMessage({ id: 'form.user.placeholder.searchServices' })}
                    size="large"
                    onChange={e => onSearch(e.target.value)}
                    allowClear
                  />
                </Form.Item>
              </div>
              <div className="col-md-3" style={{ maxWidth: '100px!important' }}>
                <Form
                  form={form}
                  layout="vertical"
                  hideRequiredMark
                  onValuesChange={onValuesChange}
                >
                  <Form.Item name="current_status" style={{ maxWidth: '100px!important' }}>
                    <Select
                      placeholder={formatMessage({ id: 'form.placeholder.CurrentSattus' })}
                      size="large"
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      return (
                      <Option key="open" value="open">
                        Open
                      </Option>
                      <Option key="closed" value="closed">
                        Closed
                      </Option>
                      )
                    </Select>
                  </Form.Item>
                </Form>
              </div>
              <div className="col-md-3" style={{ maxWidth: '100px!important' }}>
                <Form.Item>
                  <Button type="primary" size="small" onClick={clearButton}>
                    {formatMessage({ id: 'action.clear' })}
                  </Button>
                </Form.Item>
              </div>
            </div>



            <div className="kit__utils__table">
              <Suspense fallback={<Spin />}>
                {apiRes?.length >= 0 ? (
                  <Table
                    dataSource={apiRes}
                    columns={columns}
                    locale={locale}
                    rowKey="_id"
                    pagination={{
                      onChange(current) {
                        setPage(current)
                      },
                    }}
                  />
                ) : (
                  <Table
                    columns={columns}
                    locale={locale}
                    loading={{
                      indicator: (
                        <div>
                          <Spin />
                        </div>
                      ),
                    }}
                  />
                )}
              </Suspense>
              {console.log('displayIndividualServicePopupModal:', services?.displayIndividualServicePopupModal)};
              {console.log(services?.selectedData, "services?.selectedData")}
              <CloseRequest
                visible={services?.displayIndividualServicePopupModal}
                title={`Service Update - ${services?.selectedData?.service_reuest_id}`}
                onCancel={hidePopupIndService}
                loading={services?.updating}
                onFinish={UpdateService}
                infodata={services}
              />
              <ModifyCloseRequest
                visible={services?.displayModifyPopupModalIndService}
                title={`Payment Update - ${services?.selectedData?.service_reuest_id}`}
                onCancel={hideModifyIPopupIndService}
                loading={services?.updating}
                onFinish={UpdateService}
                infodata={services?.serviceHistory}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
