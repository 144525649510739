import { createSelector } from 'reselect'

export const selectServices = state => state?.services

export const selectedServicesData = createSelector([selectServices], services => {
  const { searchServices, ServicesInfodata } = services
  if (searchServices && ServicesInfodata) {
    return ServicesInfodata.filter(
      r =>
        r?.service_type?.toLowerCase().includes(searchServices.toLowerCase()) ||
        r?.category?.toLowerCase().includes(searchServices.toLowerCase()) ||
        r?.vehicle?.toLowerCase().includes(searchServices.toLowerCase()) ||
        r?.description?.toLowerCase().includes(searchServices.toLowerCase()),
    )
  }
  return ServicesInfodata
})
