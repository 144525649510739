import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Space, Spin, Tag, Button, DatePicker, Tooltip } from 'antd'
import { injectIntl } from 'react-intl'
import { getFormattedDate } from 'utils/parser'
import moment from 'moment'
import * as XLSX from 'xlsx'  // Import xlsx library

const { RangePicker } = DatePicker

const mapStateToProps = ({ dispatch, vehicle }) => ({
  dispatch,
  vehicle,
})

const StoreSettings = ({ apiRes, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)
  const [filteredData, setFilteredData] = useState(apiRes)
  const [dates, setDates] = useState([])

  useEffect(() => {
    setFilteredData(apiRes)
  }, [apiRes])

  const handleFilter = () => {
    if (dates.length === 0) {
      setFilteredData(apiRes)
    } else {
      const [start, end] = dates
      const filtered = apiRes.filter(item => {
        if (item.service_end_date) {
          const receiptDate = moment(item.service_end_date)
          return receiptDate.isBetween(start, end, null, '[]')
        }
        return false
      })
      setFilteredData(filtered)
    }
  }

  const handleClear = () => {
    setDates([])
    setFilteredData(apiRes)
  }

  const onExportClick = () => {
    // Create a workbook and a worksheet
    const ws = XLSX.utils.json_to_sheet(filteredData.map(item => ({
      'Serial No': (page - 1) * 10 + item.key + 1,
      'Service Request ID': item.service_reuest_id,
      'Description': item.description,
      'Vendor Details': `${item.vendor_name} - ${item.vendor_city}`,
      'Amount (INR)': Number(item.billamount).toFixed(2),
      'Receipt Date': getFormattedDate(item.service_end_date),
    })))

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Expenses')

    // Export the Excel file
    XLSX.writeFile(wb, 'Expenses.xlsx')
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.service_reuest_id' }),
      render: (text, record) => (
        <Tag
          color={(() => {
            switch (text?.service_reuest_id) {
              default:
                return 'blue'
            }
          })()}
          style={{ textTransform: 'capitalize' }}
        >
          {record?.service_reuest_id}
        </Tag>
      ),
    },
    {
      title: formatMessage({ id: 'text.description' }),
      dataIndex: 'description',
      key: 'description',
      render: text => (
        <Tooltip placement="top" title={text}>
          <Button type="primary">View Message</Button>
        </Tooltip>
      ),
    },
    {
      title: formatMessage({ id: 'text.vendorDetails' }),
      render: text => <>{`${text?.vendor_name} - ${text?.vendor_city} `}</>,
    },
    {
      title: formatMessage({ id: 'text.amount' }),
      dataIndex: 'billamount',
      key: 'billamount',
      render: (text, record) => <>{Number(record?.billamount).toFixed(2)} INR</>,
    },
    {
      title: formatMessage({ id: 'text.receipt_date' }),
      dataIndex: 'service_end_date',
      key: 'service_end_date',
      render: text => <>{text !== null && <>{getFormattedDate(text)}</>}</>,
    },
    {
      title: formatMessage({ id: 'text.action' }),
      key: 'action',
      render: (record, text) => (
        <Space size="middle">
          <button
            type="button"
            className="btn btn-light mb-3 mb-md-0"
            onClick={onExportClick}
            hidden={text?.document === null}
          >
            <i className="fe fe-download mr-1" aria-hidden="true" />
          </button>
        </Space>
      ),
    },
  ]

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.Expensesdata' }),
  }

  const totalSpent = filteredData?.reduce(
    (acc, curr) => ({
      amount: acc.amount + Number(curr.billamount || 0),
    }),
    { amount: 0 },
  )

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="mb-4">
            <div className="row mb-3">
              <div className="col-md-3">
                <div
                  className="rounded border border-gray-3 mb-3 p-3"
                  style={{ backgroundColor: '#03349A' }}
                  hidden={totalSpent?.amount === 0}
                >
                  <div
                    className="text-white-5 font-weight-bold font-size-14"
                    style={{ color: '#ffffff' }}
                  >
                    Total Expenses
                  </div>
                  <div
                    className={`${
                      totalSpent?.amount > 0 ? 'text-white' : 'text-white-6'
                    } font-weight-normal font-size-24`}
                    style={{ color: '#ffffff' }}
                  >
                    {totalSpent?.amount.toFixed(2)} INR
                  </div>
                </div>
              </div>
              <div className="col-md-3 mt-5">
                <RangePicker onChange={setDates} />
                <Button type="primary" onClick={handleFilter}>
                  Filter
                </Button>
              </div>
              <div className="col-md-3 mt-5">
                <Button type="primary" onClick={handleClear}>
                  Clear
                </Button>
              </div>
              <div className="col-md-3 mt-5">
                <Button type="primary" onClick={onExportClick}>
                  Export to Excel
                </Button>
              </div>
            </div>
          </div>
          <div className="kit__utils__table">
            <Suspense fallback={<Spin />}>
              <Table
                dataSource={filteredData}
                columns={columns}
                locale={locale}
                rowKey="_id"
                pagination={{
                  onChange(current) {
                    setPage(current)
                  },
                }}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
