import actions from './actions'

const initialState = {
  servicesInfo: null,
  ServicesInfodata: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,
  serviceDetail: null,
  serviceHistory: null,
  tyreSerialNoInfo: null,

  searchServices: null,
  displayAddDrawer: false,
  displayPopupModal: false,
  displayIndividualServicePopupModal: false,
  displayModifyPopupModal: false,
  displayModifyPopupModalIndService: false,
  displayViewDrawer: false,
  displayUploadModal: false,
  displayRemoveModal: false,
  displayUpdateDrawer: false,
  updating: false,
}

export default function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddDrawer: false }

    case actions.HIDE_MODIFY_POPUP:
      return { ...state, displayModifyPopupModal: false }
    case actions.HIDE_MODIFY_POPUP_IND_SERVICE:
      return { ...state, displayModifyPopupModalIndService: false }

    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.serviceInfo, displayUpdateDrawer: true };

    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false };
    case actions.SHOW_POPU_IND_SERVICE:
      console.log('Action Payload:', action.payload)
      return { ...state, selectedData: action.payload, displayIndividualServicePopupModal: true }
    case actions.HIDE_POPU_IND_SERVICE:
      return { ...state, displayIndividualServicePopupModal: false }
    case actions.SHOW_POPUP:
      console.log('48 showpopup Action Payload:', action.payload)
      return { ...state, selectedData: action.payload, displayPopupModal: true }
    case actions.HIDE_POPUP:
      return { ...state, displayPopupModal: false }    
    case actions.SHOW_DETAIL:
      return { ...state, selectedData: action.payload, displayViewDrawer: true }
    case actions.HIDE_DETAIL:
      return { ...state, displayViewDrawer: false }
    case actions.SHOW_UPLOAD:
      return { ...state, selectedData: action.payload.serviceInfo, displayUploadModal: true }
    case actions.HIDE_UPLOAD:
      return { ...state, displayUploadModal: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.vehicleInfo, displayRemoveModal: true };

    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false };
    default:
      return state
  }
}
